export enum ErrorKey {
  AccessNotAllowed = 'AccessNotAllowed',
  TokenNotValidOrExpired = 'TokenNotValidOrExpired',
  NoAccessForCurrentRole = 'NoAccessForCurrentRole',
  AutoEnrollmentLinkDisabled = 'AutoEnrollmentLinkDisabled',
  AutoEnrollmentLinkNotAvailable = 'AutoEnrollmentLinkNotAvailable',
  AutoProvisioningSettingsDisabled = 'AutoProvisioningSettingsDisabled',
  AutoProvisioningSettingsNotSupportedForLocalAccounts = 'AutoProvisioningSettingsNotSupportedForLocalAccounts',
  RecursiveHierarchy = 'RecursiveHierarchy',
  UserCantBeOwnManager = 'UserCantBeOwnManager',
  AutoEnrollmentLinkArchived = 'AutoEnrollmentLinkArchived',
  AutoEnrollmentLinkDoesNotExist = 'AutoEnrollmentLinkDoesNotExist',
  AutoProvisioningSettingsDoNotExist = 'AutoProvisioningSettingsDoNotExist'
}