import { Configuration } from 'skyllful-client-sdk';
import { getIdToken } from './authConfig';
import { ConfigEnv } from './configEnv';

export const createApiConfiguration = () => {
  return new Configuration({
    accessToken: getIdToken(),
    basePath: ConfigEnv.REACT_APP_BASE_URL
  });
};
