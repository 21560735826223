import { AuthenticationResult } from '@azure/msal-browser';
import React from 'react';

export const ProtectedData = ({responseData}: {responseData: AuthenticationResult}) => {
    return (
        <ul className="protectedData">
            <li>name={responseData?.account?.name}</li>
            <li>username={responseData?.account?.username}</li>
            <li>expiresOn={responseData?.expiresOn?.toString()}</li>
            <li>scopes={responseData?.scopes}</li>
        </ul>
    );
};
