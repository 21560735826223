import { logoutSilently } from 'config/authConfig';
import { useState, useEffect } from 'react';

interface RequestProps<T> {
  request: () => Promise<T>;
  autoSubmit?: boolean;
}

export interface ErrorMap {
  [key: string]: string;
}

export const parseErrors = (errors: any) => {
  const errorMap: ErrorMap = {};
  if (errors) {
    for (const error of errors) {
      const fieldName =
        error.fieldName !== null ? error.fieldName?.toLowerCase() : 'general';
      if (!errorMap[fieldName]) {
        errorMap[fieldName] = error.message;
      }
    }
    return errorMap;
  }
};

// Call async function
const onAutoSubmit = (autosubmit: boolean, submit: () => Promise<void>) => autosubmit && submit();
// eslint-disable-next-line react-hooks/exhaustive-deps

export const useFetch = <T>({
  request,
  autoSubmit = true,
}: RequestProps<T>) => {
  // Response state
  const [data, setData] = useState<T>();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMap, setErrorMap] = useState<ErrorMap>({});

  // Define asynchronous function
  const submit = async () => {
    try {
      // Fetch data from REST API
      setIsLoading(true);
      const instance = request();
      const response = await instance;
      setData(response);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      console.log(error);

      if (error.status === 401) {
        logoutSilently();
      }

      const errors = await error.json();
      const newErrorMap: ErrorMap = {};

      if (errors.errors) {
        for (const errorInfo of errors.errors) {
          const fieldName = errorInfo.fieldName?.toLowerCase();

          // I'm getting only the first error for each field, otherwise it could overflow the form area (this is what we do on the old studio)
          if (!newErrorMap[fieldName]) {
            newErrorMap[fieldName] = errorInfo.message;
          }
        }
      }

      setErrorMap(newErrorMap);
    }
  };

  useEffect(() => {
    onAutoSubmit(autoSubmit, submit);
  }, []);

  return { data, isLoading, errorMap, submit };
};

export const useFetch2 = <T>({
  request: fetchFunction,
  autoSubmit = true,
}: RequestProps<T>) => {
  // Response state
  const [data, setData] = useState<T>();
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState<any[]>();

  // Define asynchronous function
  const submit = async () => {
    try {
      // Fetch data from REST API
      setIsLoading(true);
      const instance = fetchFunction();
      const response = await instance;
      setData(response);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      console.log(error);

      const errorsResponse = await error.json();
      setErrors(errorsResponse);
    }
  };

  useEffect(() => {
    onAutoSubmit(autoSubmit, submit);
  }, []);

  return { data, isLoading, errors, submit };
};
