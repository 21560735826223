import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import LanguageSelect from 'common/LanguageSelect';
import { createApiConfiguration } from 'config/apiConfig';
import { callRedirectPromise, callRedirectPromiseSso, logout, logoutSilently, refreshIdToken } from 'config/authConfig';
import { ConfigEnv } from 'config/configEnv';
import { ErrorKey } from 'constants/errors';
import { UrlParameterKey } from 'constants/urlParameters';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { AutoEnrollmentLinkApi, UserInfoContract, UsersApi } from 'skyllful-client-sdk';
import styled from 'styled-components';
import { getEnrollCode } from 'util/url';

const Background = styled.div`
	min-width: 600px;
	height: 100vh;
	width: 100vw;
	padding-left: 5%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow: auto;
	background: #2c3343 url(${`${ConfigEnv.REACT_APP_PUBLIC_URL}/background.jpg`}) no-repeat no-repeat right;
	background-size: auto 100vh;
  	@media (max-height: 601px) {
        background: #2c3343 url(${`${ConfigEnv.REACT_APP_PUBLIC_URL}/background.jpg`}) no-repeat no-repeat right;
        background-size: auto 100vh;
    }
    @media (max-height: 901px) {
        background: #2c3343 url(${`${ConfigEnv.REACT_APP_PUBLIC_URL}/background.jpg`}) no-repeat no-repeat right;
        background-size: auto 100vh;
    }
    @media (max-height: 1201px) {
        background: #2c3343 url(${`${ConfigEnv.REACT_APP_PUBLIC_URL}/background.jpg`}) no-repeat no-repeat right;
        background-size: auto 100vh;
    }
    @media (max-height: 1802px) {
        background: #2c3343 url(${`${ConfigEnv.REACT_APP_PUBLIC_URL}/background.jpg`}) no-repeat no-repeat right;
        background-size: auto 100vh;
    }
    @media (min-height: 2402px) {
        background: #2c3343 url(${`${ConfigEnv.REACT_APP_PUBLIC_URL}/background.jpg`}) no-repeat no-repeat right;
        background-size: auto 100vh;
    }
    @media (max-width: 375px) and (max-height: 768px) {
        background: url(${`${ConfigEnv.REACT_APP_PUBLIC_URL}/background.jpg`}) no-repeat no-repeat right;
        box-shadow: inset 0 0 0 2000px rgba(44, 51, 67, 0.4);
        background-size: auto 109vh;
        background-position: 127%;
    }

    @media (min-width: 376px) and (max-width: 414px) and (min-height: 717px) and (max-height: 896px) {
        background: url(${`${ConfigEnv.REACT_APP_PUBLIC_URL}/background.jpg`}) no-repeat no-repeat right;
        box-shadow: inset 0 0 0 2000px rgba(44, 51, 67, 0.4);
        background-size: auto 109vh;
        background-position: 107%;
    }
`;

const Logo = styled.div`
	background: url(${`${ConfigEnv.REACT_APP_PUBLIC_URL}/skyllful_logo.svg`}) no-repeat no-repeat 0 50px;
	background-size: 400px auto;
	height: 28%;
	width: 400px;
	min-height: 185px;
`;

const Intro = styled.div`
	color: #ffffff;
	display: flex;
	flex-direction: column;
	width: 420px;
	justify-content: space-between;
	h3 {
		font-size: 44px;
		margin: 5px 0;
	}
	h4 {
		font-size: 36px;
		font-weight: 400;
		margin: 5px 0;
	}
	p {
		margin-top: 24px;
		font-size: 23px;
	}
	 @media (min-width: 320px) and (max-width: 480px) {
		h3 {
	        font-size: 22px;
	        margin: 5px 0;
	    }
	    h4 {
      		font-size: 36px;
        	font-weight: 400;
        	margin: 5px 0;
    	}
    	p {
    	    width: 95vw;
    	    margin-top: 19px;
    	    font-size: 16px;
   		 }
 	  		margin-bottom: 15%;
	 	}
`;

const LoginButton = styled.button`
	background-color: #399c74;
	color: #ffffff;
	cursor: pointer;
	height: 50px;
	width: 418px;
	line-height: 50px;
	text-align: center;
	font-size: 26px;
	font-weight: 500;
	border: none;
	margin-top: 3%;
	margin-bottom: 20%;
     @media (min-width: 320px) and (max-width: 480px) {
		width: 90vw;
   		margin-bottom: 20%;
     }
	@media (min-device-width: 1200px) and (max-device-width: 1600px) {
  	   		margin-bottom: 5%;
	}
`;

const ErrorMessage = styled.span`
	color: #721c24;
	background-color: #f8d7da;
	border-color: #f5c6cb;
	position: absolute;
  padding: 0.75rem 1.25rem;
	margin-bottom: 1rem;
  border: 1px solid transparent;
	border-radius: 0.25rem;
	left: 0px;
	right: 0px;
	font-size: 14px;
`;

const SuccessMessage = styled.span`
	color: #fff;
	background-color: rgba(57, 171, 136, 1);
	border-color: rgb(57, 171, 136);
	position: absolute;
  padding: 0.75rem 1.25rem;
	margin-bottom: 1rem;
  border: 1px solid transparent;
	border-radius: 0.25rem;
	left: 0px;
	right: 0px;
	font-size: 14px;
`;

const LoginPage = () => {
	const { t } = useTranslation();
	const isAuthenticated = useIsAuthenticated();
	const { inProgress } = useMsal();
	const [errorMessage, setErrorMessage] = useState<string>();
	const [successMessage, setSuccessMessage] = useState<string>();
	const [showRedirectingSimulator, setShowRedirectingSimulator] = useState<boolean>();
	const [isLoadingAuth, setIsLoadingAuth] = useState<boolean>(false);

	const isSimulator = (new URLSearchParams(window.location.search)).get(UrlParameterKey.IsSimulator);
	let returnUrl = (new URLSearchParams(window.location.search)).get(UrlParameterKey.ReturnUrl);
	const errorKey = (new URLSearchParams(window.location.search)).get(UrlParameterKey.Error);
	const isAdmin = (new URLSearchParams(window.location.search)).get(UrlParameterKey.IsAdmin) === 'true';
	const enrollTrainingCampaignId = (new URLSearchParams(window.location.search)).get(UrlParameterKey.EnrollTrainingCampaignId);
	const enrollCode = getEnrollCode();

	const redirectToClientApps = () => {
		setIsLoadingAuth(true);
    	refreshIdToken()
      		.then(async () => {
				const isUsingEnrollCode = enrollCode != null && enrollCode != "" && ConfigEnv.REACT_APP_ENABLE_AUTO_ENROLLMENT;
				const isUsingEnrollTrainingCampaignId = enrollTrainingCampaignId != null && enrollTrainingCampaignId != "" && ConfigEnv.REACT_APP_ENABLE_AUTO_ENROLLMENT;

				let hasEnrollError = false;

				if (isUsingEnrollCode) {
					const enrollApi = new AutoEnrollmentLinkApi(createApiConfiguration());

					returnUrl = ConfigEnv.REACT_APP_SIMULATOR_URL;
					
					try {
						await enrollApi.apiAutoenrollmentlinksEnrollPost({ code: enrollCode ?? undefined});				
						setSuccessMessage(t('success.autoEnrolledSuccessfully'));
					} catch (error: any) {
						const errorObject = await error.json();
						hasEnrollError = true;
						setErrorMessageForErrorKey(errorObject.errors[0].errorKey);
					}
				} else if (isUsingEnrollTrainingCampaignId) {
					const enrollApi = new AutoEnrollmentLinkApi(createApiConfiguration());
					
					try {
						await enrollApi.apiAutoenrollmentlinksEnrollPost({ trainingCampaignId: enrollTrainingCampaignId ?? undefined});
					} catch (error: any) {
						const errorObject = await error.json();
						setErrorMessageForErrorKey(errorObject.errors[0].errorKey, errorObject.errors[0].message);
						return;
					}
				}
					
				getUserInfoAndRedirect(isUsingEnrollCode)
					.catch((error) => handleError(error, hasEnrollError));
			})
			.catch((error) => {
				handleError(error);
			});
	};

	const getUserInfoAndRedirect = async (isUsingEnrollCode: boolean) => {
		const api = new UsersApi(createApiConfiguration());
		const currentUser = await api.apiUsersCurrentGet();
		const hasAccessToSimulator = currentUser?.roles?.indexOf('Simulator') != -1;

		if (isUsingEnrollCode) {
			if (hasAccessToSimulator) {
				setShowRedirectingSimulator(hasAccessToSimulator);
				setTimeout(() => navigateToUrlIfHasAccess(currentUser), 3000);
			}
		} else {
			setErrorMessage(undefined);
			navigateToUrlIfHasAccess(currentUser);
		}		
	};

	const navigateToUrlIfHasAccess = (currentUser: UserInfoContract) => {
		const hasAccessToStudio = currentUser?.roles?.indexOf('Admin') != -1 || currentUser?.roles?.indexOf('Contributor') != -1;
		const hasAccessToIQ = currentUser?.roles?.indexOf('Dashboard') != -1;
		const hasAccessToSimulator = currentUser?.roles?.indexOf('Simulator') != -1;

		if (returnUrl) {
			const decodedReturnUrl = decodeURIComponent(returnUrl);

			const isNavigatingToStudio = decodedReturnUrl.indexOf(ConfigEnv.REACT_APP_STUDIO_V2_URL) !== -1;
			if (isNavigatingToStudio && !hasAccessToStudio) {
				setErrorMessageForErrorKey(ErrorKey.NoAccessForCurrentRole);
				return;
			}

			const isNavigatingToIQ = decodedReturnUrl.indexOf(ConfigEnv.REACT_APP_IQ_URL) !== -1;
			if (isNavigatingToIQ && !hasAccessToIQ) {
				setErrorMessageForErrorKey(ErrorKey.NoAccessForCurrentRole);
				return;
			}

			const isNavigatingToSimulator = decodedReturnUrl.indexOf(ConfigEnv.REACT_APP_SIMULATOR_URL) !== -1;
			if (isNavigatingToSimulator && !hasAccessToSimulator) {
				setErrorMessageForErrorKey(ErrorKey.NoAccessForCurrentRole);
				return;
			}

			window.location.href = returnUrl;
		} else if (hasAccessToStudio) {
			window.location.href = ConfigEnv.REACT_APP_STUDIO_V2_URL;
		} else if (hasAccessToIQ) {
			window.location.href = ConfigEnv.REACT_APP_IQ_URL;
		} else {
			window.location.href = ConfigEnv.REACT_APP_SIMULATOR_URL;
		}
	};

	const handleError = (error: any, hasEnrollError?: boolean) => {
		error.json().then((errorResult: any) => {
			if (errorResult.errors.length > 0) {	
				// If has enroll error and it failed because the user doesn't exist then we keep the enroll error
				if (!hasEnrollError) {
					setErrorMessageForErrorKey(errorResult.errors[0].errorKey);
				}				
			}					
		}).catch((err: any) => {
			console.log(err);
		});

		// If we can't get a token then we logout the user
		setIsLoadingAuth(false);
		logoutSilently();
	};

	const setErrorMessageForErrorKey = (errorKey: string, errorMessage?: string) => {
		if (errorKey) {
			var mappedErrorIndex = Object.keys(ErrorKey).findIndex(x => x.toLowerCase() == errorKey.toLowerCase());
			if (mappedErrorIndex != -1) {
				setErrorMessage(t(`error.${Object.values(ErrorKey)[mappedErrorIndex]}`));
			} else if (errorMessage) {
				setErrorMessage(errorMessage);
			} else {
				setErrorMessage(t('error.GenericError'));
			}
		} else {
			setErrorMessage(t('error.GenericError'));
		}
	};
	
	useEffect(() => {
		if (errorKey) {
			setErrorMessageForErrorKey(errorKey);
		}
	}, [errorKey]);

	const shouldCheckForSso = () => {
		return !isAdmin && inProgress === InteractionStatus.None && !isAuthenticated && !isLoadingAuth && ConfigEnv.REACT_APP_ENABLE_AUTO_SSO_LOGIN;
	};

	// Retrieve an access token
	useEffect(() => {
		const authenticationIsDoneSuccessfully = inProgress === InteractionStatus.None && isAuthenticated;
		const isTokenNotValid = errorMessage === t('error.TokenNotValidOrExpired');

		// If user is authentiated but message is token not valid we try to get a new token and redirect the user back to the application
		if (authenticationIsDoneSuccessfully && (!errorMessage || isTokenNotValid)) {
			redirectToClientApps();
		} else if (shouldCheckForSso()) {
			setIsLoadingAuth(true);
			callRedirectPromiseSso();
		}
	}, [inProgress, isAuthenticated]);
	
	const showLoadingText = isLoadingAuth || inProgress != InteractionStatus.None;
	const loginText = showLoadingText ? t('loginButtonLoading') : t('loginButton');
	const logoutText = showLoadingText ? t('loginButtonLoading') : t('logoutButton');

	return (
		<Background>
			<Logo><br /></Logo>
			<Intro>
				<h3>{t('loginPage.title')}</h3>
				<p>
					<Trans>{t('loginPage.description')}</Trans>
				</p>
			</Intro>
			<div>
			{isSimulator && <LanguageSelect />}
			{isAuthenticated // TEMPORARY: ONLY WHILE IT'S A WIP
				? <LoginButton disabled={isLoadingAuth} onClick={logout}>{logoutText}</LoginButton>
				: <LoginButton disabled={isLoadingAuth} onClick={() => callRedirectPromise()}>{loginText}</LoginButton>
			}
			</div>
			{errorMessage &&
				<ErrorMessage>{errorMessage} {showRedirectingSimulator && t('redirectingToSimulator')}</ErrorMessage>
			}
			{successMessage &&
				<SuccessMessage>{successMessage} {t('redirectingToSimulator')}</SuccessMessage>
			}
	  	</Background>
	);
};

export default LoginPage;
