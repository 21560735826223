const english = {
  'error.TokenNotValidOrExpired': 'Your session has expired, please sign in again.',
  'error.AccessNotAllowed': 'No access for requested role, please request access to your admin.',
  'error.NoAccessForCurrentRole': 'No access for requested role, please request access to your admin.',  
  'error.AutoEnrollmentLinkDisabled': 'The specified enroll code is disabled, please contact your admin.',  
  'error.AutoEnrollmentLinkNotAvailable': 'The specified enroll code is not available, please contact your admin.',
  'error.AutoProvisioningSettingsDisabled': 'Auto provisioning is disabled, please contact your admin.',  
  'error.AutoProvisioningSettingsNotSupportedForLocalAccounts': 'Auto provisioning is not supported for local accounts, please contact your admin.',  
  'error.RecursiveHierarchy': 'The specified enroll code conflicts with the hierarchy for your user, please contact your admin.',
  'error.UserCantBeOwnManager' : 'The specified enroll code conflicts with the hierarchy for your user, please contact your admin.',
  'error.AutoEnrollmentLinkArchived': 'The specified enroll code is archived, please contact your admin.',
  'error.AutoEnrollmentLinkDoesNotExist': 'The specified enroll code does not exist, please contact your admin.',
  'error.AutoProvisioningSettingsDoNotExist': 'There is no auto provisioning settings for the specified enroll code, please contact your admin.',
  'error.GenericError': 'There was an error on our system, please contact your admin.',  
  'loginButton': 'GET STARTED',
  'loginButtonLoading': 'LOADING',
  'logoutButton': 'LOG OUT',
  'loginPage.title': 'Get smart about training.',
  'loginPage.description': 'Never be in doubt about the readiness of your workforce. With multiple metrics and visualizations, you will be able to assess the effectiveness of your training initiatives in real time.',
  'skyllful.loginPage.title': 'On Demand',
  'skyllful.loginPage.title2': 'On Device',
  'skyllful.loginPage.title3': 'App Training Simulator',
  'skyllful.loginPage.description': 'Learn and practice how to use the apps you need to do your job in a <strong>anxiety free</strong> and <strong>self-paced</strong> manner. No need to worry about breaking anything.',
  'skyllful.loginPage.defaultError': 'Sorry. Login failed.',
  'success.autoEnrolledSuccessfully': 'You were successfully enrolled.',
  'redirectingToSimulator': 'Redirecting to simulator...'
};

export default english;