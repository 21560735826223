export const getEnrollCode = (): string | null => {
  var urlParts = window.location.pathname.split('/');
  if (urlParts.indexOf('enroll') === -1) {
    return null;
  }

  if (urlParts.length < 4) {
    return null;
  }

  return urlParts[3];
};