import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import english from './english';
import spanish from './spanish';

const i18 = () => {

  const resources = {
    en: {
      translation: english,
    },
    es: {
      translation: spanish,
    }
  };

  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
      fallbackLng: 'en',
      debug: false,
      interpolation: {
        escapeValue: false // react already safes from xss
      },
      keySeparator: false, // we do not use keys in form messages.welcome
      resources,
    });

  return i18n;
};

export default i18;