import { useEffect, useState } from 'react';

// Msal imports
import { MsalAuthenticationTemplate, useMsal, useAccount } from '@azure/msal-react';
import { InteractionType, InteractionStatus, InteractionRequiredAuthError, AuthenticationResult } from '@azure/msal-browser';
import { silentloginRequest } from '../config/authConfig';
import { ProtectedData } from 'example items/ProtectedData';

const ProtectedContent = () => {
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [atsResponse, setAtsResponse] = useState<AuthenticationResult>();

    useEffect(() => {
        if (!atsResponse && account && inProgress === InteractionStatus.None) {
            const request = {
                ...silentloginRequest,
                account
            };
            instance.acquireTokenSilent(request).then((response) => {
                setAtsResponse(response);
            }).catch((e) => {
                if (e instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(request);
                }
            });
        }
    }, [account, inProgress, instance, atsResponse]);

    return (
        <div>
            { atsResponse ? <ProtectedData responseData={atsResponse} />  : null}
        </div>
    );
};

export const Protected = () => {
    const authRequest = {
        ...silentloginRequest
    };

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Popup}
            authenticationRequest={authRequest}
        >
            <ProtectedContent />
        </MsalAuthenticationTemplate>
    );
};
