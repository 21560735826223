import { createApiConfiguration } from 'config/apiConfig';
import { useFetch } from 'hooks/useFetch';
import React, { useEffect, useState } from 'react';
import { LanguageContract, SimulatorApi } from 'skyllful-client-sdk';
import { IconGlobeAmericas } from 'skyllful-components';
import styled from 'styled-components';

const LanguageContainer = styled.div`
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    .iconGlobe {
        display: flex;
        justify-items: center;
        align-items: center;
        margin-right: 15px;
        svg {
          height: 38px;
          width: auto;
        }
    }
`;

const Languages = styled.div`
    width: 418px;
    display: flex;
    flex-direction: row;
`;

const LanguageItem = styled.div`
    align-content: center;
    background: white;
    border-color: #399c74;
    border-style: solid;
    border-width: 1px 0;
    color: #96abb0;
    cursor: pointer;
    font-size: 20px;
    font-weight: 600;
    padding: 5px 30px;
    text-align: center;
    text-transform: uppercase;
    width: fit-content;
    &:only-child {
    border-radius: 3px;
    }
    &:not(:only-child) {
    &:first-child{
        border-radius: 3px 0 0 3px;
        border-width: 1px 0 1px 1px;
    }
    &:last-child{
        border-radius: 0 3px 3px 0;
        border-width: 1px 1px 1px 0;
    }

    }
    &.active {
        background: #399c74;
        color: white;
    }
`;

const LanguageSelect = () => {
    const LANGUAGE_CODE = 'iq.language';
    const DEFAULT_LANGUAGE = 'en';

    const api = new SimulatorApi(createApiConfiguration());
    const apiCallGet = () => api.apiSimulatorLanguagesGet();
    const getLanguages = useFetch<LanguageContract[]>({ request: apiCallGet });
    const {data: languages} = getLanguages;

    const [languageIsoCode, setLanguageIsoCode] = useState<string>(DEFAULT_LANGUAGE);

    const onSelectLanguage = (isoCode: string) => {
        setLanguageIsoCode(isoCode);
        localStorage.setItem(LANGUAGE_CODE, isoCode);
    };

    useEffect(() => {
        const languageInStorage = localStorage.getItem(LANGUAGE_CODE);

        if (languageInStorage) {
            setLanguageIsoCode(languageInStorage);
        }
    }, []);

    useEffect(()=> {
        const languageInStorage = localStorage.getItem(LANGUAGE_CODE);

        if (languageInStorage && languages) {
            const languageExists = languages.find((languageContract) => languageContract.isoCode === languageInStorage);

            if (!languageExists) {
                const isDefaultLangExist = languages.find((languageContract) => languageContract.isoCode === DEFAULT_LANGUAGE);
                const firstLanguage = languages[0].isoCode;
                if (isDefaultLangExist || firstLanguage) {
                    onSelectLanguage(isDefaultLangExist || !firstLanguage? DEFAULT_LANGUAGE : firstLanguage);
                }
            }
        }
    }, [languages]);

    return (
        <LanguageContainer>
            <div className="iconGlobe"><IconGlobeAmericas color="gray" size="medium" /></div>
            <Languages>
            {languages && languages.map((languageItem) => (
                <LanguageItem
                    key={languageItem.isoCode}
                    className={languageItem.isoCode === languageIsoCode ? 'active' : ''}
                    onClick={() => languageItem.isoCode && onSelectLanguage(languageItem.isoCode)}
                >
                    {languageItem.name}
                </LanguageItem>
            ))}
            </Languages>
        </LanguageContainer>
    );
};

export default LanguageSelect;
